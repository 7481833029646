import React from "react";
import Layout from "../components/layout/layout";
import ContactSection from "../page-sections/contact-section/contact-section";

export default function Contact() {
  return <>
    <Layout>
      <ContactSection />
    </Layout>
  </>
}
