import React from "react"
import * as styles from "./contact-section.module.scss"
import {Container, Row, Col} from "react-bootstrap";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import CentralHeaderRow from "../../components/central-header/central-header";
import VerticalSpacingSmallRow from "../../components/vertical-spacing-small/vertical-spacing-small";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

export default function ContactSection(props) {

    const data = useStaticQuery(graphql`{
    imagePlaceholder: file(relativePath: {eq: "beefteka/map-placeholder.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 50, layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
  }`);

    return <>
        <VerticalSpacingRow/>
        <VerticalSpacingRow/>
        <CentralHeaderRow
            header="KONTAKT"
        ></CentralHeaderRow>

        <VerticalSpacingSmallRow/>

        <div>
            <iframe title="map" className={styles.map} width="100%" height="100%"
                    style={{border: 0, position: 'absolute', zIndex: 1}} loading="eager" allowFullScreen
                    src={"https://www.google.com/maps/embed/v1/place?q=place_id:ChIJU1n5bkIVG0cRNlzfmvoBZCU&key=AIzaSyCwajEs8nWicvYvCZ5BuOUll53jabF6lD4&language=pl&zoom=14"}></iframe>

            <GatsbyImage
                width="100%"
                height="100%"
                className={styles.map}
                image={data.imagePlaceholder.childImageSharp.gatsbyImageData}
                objectFit="cover"
            />
        </div>

        <VerticalSpacingSmallRow/>

        <Container>
            <Row>
                <Col md={4}>
                    <Row className={styles.infoWrapper}>
                        <div>
                            <div className={styles.columnHeader}>
                                BROWAR KOŁO
                            </div>
                            <div className={styles.columnLink}>
                                <a href="https://goo.gl/maps/Qq7qVwT6wXh4cRQK9" target="_blank">
                                    ul. Henryka Sienkiewicza 35
                                    <br/>
                                    62-600 Koło
                                </a>
                            </div>
                        </div>
                    </Row>
                </Col>
                <Col md={4}>
                    <Row className={styles.infoWrapper}>
                        <div>
                            <div className={styles.columnHeader}>
                                REZERWACJE
                            </div>

                            <div className={styles.columnLink}>
                                <a href="mailto:kontakt@browarkolo.pl">kontakt@browarkolo.pl</a>
                            </div>
                            <div className={styles.columnLink}>
                                <a href="tel:+48 535 76 76 76">+48 535 76 76 76</a>
                            </div>
                        </div>
                    </Row>
                </Col>
                <Col md={4}>
                    <Row className={styles.infoWrapper}>
                        <div>
                            <div className={styles.columnHeader}>
                                GODZINY OTWARCIA
                            </div>
                            <div className={styles.columnInfo}>
                                Pon. - czw. 12:00 - 22:00
                            </div>
                            <div className={styles.columnInfo}>
                                Pt. - sob. 12:00 - 23:00
                            </div>
                            <div className={styles.columnInfo}>
                                Niedziela 13:00 - 21:00
                            </div>
                        </div>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
}
